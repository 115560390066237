import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Typo, makeStyles, Icon, Container, Group} from '@tomfxs/pukeko';

// CSS
const useStyles = makeStyles((theme) => ({
  headline: {
    marginBottom: 7,
    fontSize: '1.125rem',
  },
  marginTop: {
    marginTop: '16px',
  },
  formControl: {
    display: 'flex',
    '& .icon': {
      margin: '1px 8px 0 0',
      alignSelf: 'flex-start',
      color: theme.palette.customColors.accent.red3,
    },
    '& .label': {
      alignSelf: 'flex-start',
    },
  },
}));

// --- COMPONENT ---
const DisplayOptions = ({
  display,
  showAll,
  showAvailableOnly,
  showNotAvailableOnly,
  showNotDefinedOnly,
}) => {
  // VARIABLES
  const {t} = useTranslation();
  const classes = useStyles();

  // FUNCTIONS

  // RENDER
  return (
    <>
      <Typo title6 className={classes.headline}>
        {t('Anzeigen')}:
      </Typo>
      <Container>
        <Group item xs={6}>
          <div
            data-testid="displayAll"
            // alle anzeigen
            onClick={() => {
              showAll();
            }}
            className={classes.formControl}>
            <Icon className="icon">
              {display === 'all'
                ? 'radio_button_checked'
                : 'radio_button_unchecked'}
            </Icon>
            <Typo subtitle1 className="label">
              {t('alle')}
            </Typo>
          </div>
          <div
            data-testid="displayAvailable"
            // verfügbare anzeigen
            onClick={() => {
              showAvailableOnly();
            }}
            className={classes.formControl}>
            <Icon className="icon">
              {display === 'avail'
                ? 'radio_button_checked'
                : 'radio_button_unchecked'}
            </Icon>
            <Typo subtitle1 className="label">
              {t('alle verfügbare')}
            </Typo>
          </div>
        </Group>

        <Group item xs={6}>
          <div
            data-testid="displayNotAvailable"
            // nicht verfügbare anzeigen
            onClick={() => {
              showNotAvailableOnly();
            }}
            className={classes.formControl}>
            <Icon className="icon">
              {display === 'notAvail'
                ? 'radio_button_checked'
                : 'radio_button_unchecked'}
            </Icon>
            <Typo subtitle1 className="label">
              {t('alle nicht verfügbare')}
            </Typo>
          </div>
          <div
            data-testid="displayNotEdited"
            // nicht bearbeitete anzeigen
            onClick={() => {
              showNotDefinedOnly();
            }}
            className={classes.formControl}>
            <Icon className="icon">
              {display === 'notEdited'
                ? 'radio_button_checked'
                : 'radio_button_unchecked'}
            </Icon>
            <Typo subtitle1 className="label">
              {t('alle nicht bearbeitete')}
            </Typo>
          </div>
        </Group>
      </Container>
    </>
  );
};

DisplayOptions.propTypes = {
  display: PropTypes.oneOf(['all', 'avail', 'notAvail', 'notEdited']),
  showAll: PropTypes.func,
  showAvailableOnly: PropTypes.func,
  showNotAvailableOnly: PropTypes.func,
  showNotDefinedOnly: PropTypes.func,
};

export default DisplayOptions;
