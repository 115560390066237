import React, {lazy, Suspense} from 'react';
import {BrowserRouter, Link, Route, Switch} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import PrivateRoute from './lib/PrivateRoute';

import ArticlesContainer from './ArticlesContainer';

import {Spinner} from '@tomfxs/pukeko';

const Login = lazy(() => import('./Login'));

const Router = (props) => {
  const history = createBrowserHistory();

  return (
    <BrowserRouter history={history}>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/">
            <Login></Login>
          </Route>

          <PrivateRoute exact path="/articles" component={ArticlesContainer} />
          <Route component={() => <div>404 Not found!</div>} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export {Link, Router};
