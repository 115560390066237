import Backend from 'i18next-http-backend';
import Cookie from 'js-cookie';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

const browserLanguage = navigator.language.split('-')[0];
//const browserLanguage = 'nl'; // für Tests

const defaultLang = Cookie.get('lang') || browserLanguage;
Cookie.set('lang', defaultLang, {expires: 365});

i18n
  .use(Backend)
  .use(initReactI18next)
  .init(
    {
      lng: defaultLang,
      backend: {
        loadPath: '/locales/{{lng}}.json',
      },
      fallbackLng: 'de',
      debug: false,
      ns: ['translations'],
      defaultNS: 'translations',
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true,
      },
    },
    (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      t('key'); // -> same as i18next.t
    }
  );

export default i18n;
