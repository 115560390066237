import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Typo, Dialog, Container, Group, makeStyles} from '@tomfxs/pukeko';

const useStyles = makeStyles((theme) => ({
  highlight: {
    color: theme?.palette?.customColors?.accent?.red9,
  },
  marginBtmSM: {
    marginBottom: 24,
  },
  marginBtmMD: {
    marginBottom: 48,
  },
  marginBtmLG: {
    marginBottom: 64,
  },
}));

// --- COMPONENT ---
const DialogReview = ({dialogReview, setDialogReview, reviewResult}) => {
  // VARIABLES
  const {t} = useTranslation();
  const classes = useStyles();

  // RENDER
  return (
    <Dialog
      title={t('Prüfen')}
      open={dialogReview}
      onClose={() => setDialogReview(false)}>
      {reviewResult.length !== 0 ? (
        <Container>
          <Group item xs={1} md={3} />
          <Group item xs={10} md={6}>
            <Group x={12} className={classes.marginBtmSM}></Group>
            <Typo title5 gutterBottom>
              {t('Folgende Artikel sind noch nicht bearbeitet')}:
            </Typo>
            <Group x={12} className={classes.marginBtmSM}></Group>

            {reviewResult.map((article, index) => {
              return (
                <>
                  <Typo
                    subtitle1
                    key={`article-${index}`}
                    className={classes.highlight}>
                    {article.artikelnr}, {article.bezeichnung},
                    <span>&nbsp;</span>
                    {t('Lieferant')}:<span>&nbsp;</span>
                    {article.lieferantnr} {article.lieferantname}
                  </Typo>
                </>
              );
            })}

            <Group x={12} className={classes.marginBtmSM}></Group>
            <Typo subtitle1>
              {t(
                'Bitte setzen Sie bei diesen Artikeln die Verfügbarkeit auf verfügbar oder nicht verfügbar.'
              )}
            </Typo>
          </Group>
          <Group item xs={1} md={3} />
        </Container>
      ) : (
        <Typo subtitle1>{t('Alle Artikel sind bearbeitet.')}</Typo>
      )}
    </Dialog>
  );
};

DialogReview.propTypes = {
  dialogReview: PropTypes.bool,
  setDialogReview: PropTypes.func,
  reviewResult: PropTypes.array,
};

export default DialogReview;
