import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Group,
  Container,
  Paper,
  Select,
  Typo,
  makeStyles,
} from '@tomfxs/pukeko';

// CSS
const useStyles = makeStyles((theme) => ({
  headline: {
    marginTop: 16,
    marginLeft: 24,
    fontSize: '1.125rem',
    display: 'inline-block !important',
  },
}));

// --- COMPONENT ---
const Supplier = ({
  dbCopyUser,
  suppliers,
  currentSupplier,
  setCurrentSupplier,
  setDbCopy,
  setArticles,
  setSearch,
  setSorting,
  setDisplay,
  setBtnSetAllToIsClickable,
}) => {
  // VARIABLES
  const {t} = useTranslation();
  const classes = useStyles();

  // FUNKTIONS
  const onChange = (event) => {
    setCurrentSupplier(event.target.value);
    const newDbCopy = dbCopyUser.filter(
      (article) => article.lieferantnr === event.target.value
    );
    setDbCopy(newDbCopy);
    setArticles(newDbCopy);

    setSearch('');
    setSorting('byArticle');
    setDisplay('all');
    setBtnSetAllToIsClickable(true);
  };

  // RENDER
  if (suppliers.length > 1) {
    return (
      <>
        <Paper padded>
          <Container data-testid="supplier-container">
            <Group xs={4}>
              <Typo title6 className={classes.headline}>
                {t('Lieferant')}:
              </Typo>
            </Group>
            <Group xs={8}>
              <Select
                data-testid="supplier-select"
                items={suppliers.map((supplier) => {
                  return {
                    value: supplier.supplierNumber,
                    text: `${supplier.supplierName} ${supplier.supplierNumber}`,
                  };
                })}
                label={t('Lieferant wählen')}
                value={`${currentSupplier}`}
                onChange={(event) => onChange(event)}></Select>
            </Group>
          </Container>
        </Paper>
      </>
    );
  } else {
    return (
      <>
        <Container></Container>
      </>
    );
  }
};

Supplier.propTypes = {
  dbCopyUser: PropTypes.arrayOf(PropTypes.object),
  suppliers: PropTypes.arrayOf(PropTypes.object),
  currentSupplier: PropTypes.string,
  setCurrentSupplier: PropTypes.func,
  setDbCopy: PropTypes.func,
  setArticles: PropTypes.func,
  setSearch: PropTypes.func,
  setSorting: PropTypes.func,
  setDisplay: PropTypes.func,
  setBtnSetAllToIsClickable: PropTypes.func,
};

export default Supplier;
