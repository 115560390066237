import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Button, makeStyles} from '@tomfxs/pukeko';

const useStyle = makeStyles((theme) => ({
  button: {
    backgroundColor: `${theme.palette.button.background} !important`,
    color: `${theme.palette.button.text} !important`,
  },
}));

// --- COMPONENT ---
const Review = ({dbCopyUser, setReviewResult, setDialogReview}) => {
  // VARIABLES
  const {t} = useTranslation();
  const classes = useStyle();

  // FUNCTIONS
  const checkIfAvailIsSet = () => {
    const list = dbCopyUser.filter((article) => {
      return article.verfuegbar === false && article.nichtverfuegbar === false;
    });
    setReviewResult(list);
    setDialogReview(true);
  };

  return (
    <>
      <Button
        contained
        startIcon="fact_check"
        className={classes.button}
        onClick={() => checkIfAvailIsSet(dbCopyUser)}
        data-testid="btnReview">
        {t('Prüfen')}
      </Button>
    </>
  );
};

Review.propTypes = {
  dbCopyUser: PropTypes.arrayOf(PropTypes.object),
  setReviewResul: PropTypes.func,
  setDialogReview: PropTypes.func,
};

export default Review;
