import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import {
  Dialog,
  Typo,
  Container,
  Group,
  Input,
  makeStyles,
} from '@tomfxs/pukeko';

const useStyles = makeStyles((theme) => ({
  inputTableRow: {
    border: '1px solid #000',
    borderColor: theme?.palette?.primary?.light,
    paddingRight: '4px !important',
    paddingLeft: '4px !important',
    margin: '-4px 0 4px 0',
    borderRadius: '4px',
    backgroundColor: '#fff',
  },
  marginBtmSM: {
    marginBottom: 24,
  },
  marginBtmMD: {
    marginBottom: 48,
  },
  marginBtmLG: {
    marginBottom: 64,
  },
}));

// --- COMPONENT ---
const DialogExcelViaMail = ({
  dialogSendMail,
  cancelDialogSendMail,
  messageText,
  setMessageText,
  sendMail,
  loading,
}) => {
  // VARIABLES
  const {t} = useTranslation();
  const classes = useStyles();

  // FUNCTIONS

  // RENDER
  return (
    <>
      <Dialog
        title={t('Fertig melden')}
        open={dialogSendMail}
        onClose={() => sendMail()}
        onCancel={() => {
          cancelDialogSendMail();
        }}>
        <Container>
          <Group item xs={1} md={3} />
          <Group item xs={10} md={6}>
            <Group x={12} className={classes.marginBtmSM}></Group>
            <Typo variant="body1">
              {t('Sie können Ihrer Meldung eine Nachricht hinzufügen.')}
            </Typo>
            <Group x={12} className={classes.marginBtmSM}></Group>
            <Input
              multiline
              rows={5}
              disableUnderline
              fullWidth
              onChange={(event) => setMessageText(event.target.value)}
              value={messageText}
              className={classes.inputTableRow}
              placeholder={t('Ihre Nachricht')}
              data-testid="dialog-excel-via-email-input"></Input>
            <Group x={12} className={classes.marginBtmMD}></Group>
            <Typo title6>{t('Meldung senden?')}</Typo>
            {loading ? (
              <Typo variant="body1">{t('E-Mail wird gesendet ...')}</Typo>
            ) : (
              ''
            )}
          </Group>
          <Group item xs={1} md={3} />
        </Container>
      </Dialog>
    </>
  );
};

DialogExcelViaMail.propTypes = {
  dialogSendMail: PropTypes.bool,
  cancelDialogSendMail: PropTypes.func,
  messageText: PropTypes.string,
  setMessageText: PropTypes.func,
  sendMail: PropTypes.func,
  loading: PropTypes.object,
};

export default DialogExcelViaMail;
