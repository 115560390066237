import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Typo, Input, makeStyles} from '@tomfxs/pukeko';

const useStyle = makeStyles((theme) => ({
  headline: {
    marginBottom: 10,
    fontSize: '1.125rem',
  },
  search: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.contrastText,
    borderRadius: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.contrastText,
    padding: '5px 12px',
    '& .material-icons': {
      color: theme.palette.primary.contrastText,
      fontSize: 33,
    },
  },
}));

// --- COMPONENT ---
const Search = ({search, setSearch, handleSearch, searchOnKeyReturn}) => {
  // VARIABLES
  const {t} = useTranslation();
  const classes = useStyle();

  // RENDER
  return (
    <>
      <Typo title6 className={classes.headline}>
        {t('Suchen')}:
      </Typo>

      <Input
        helperText={t('ArtikelNr oder Bezeichnung eingeben')}
        fullWidth
        disableUnderline
        className={classes.search}
        endIcon="search"
        placeholder={t('ArtikelNr oder Bezeichnung')}
        onClick={handleSearch}
        onKeyDown={(event) => searchOnKeyReturn(event)}
        onChange={(event) => {
          setSearch(event.target.value);
        }}
        value={search}
        data-testid="search"
      />
    </>
  );
};

Search.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
  handleSearch: PropTypes.func,
  searchOnKeyReturn: PropTypes.func,
};

export default Search;
