import React from 'react';
import {useMutation} from '../lib/graphql';
import {UPDATE_EKNEU, UPDATE_AVAILABILITY} from '../queries/availability';

import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import {Icon, Typo, makeStyles, useTheme} from '@tomfxs/pukeko';
import utils from '../utils/utils';
import TableArticles from './TableArticles';

// CSS
const useStyle = makeStyles((theme) => ({
  error: {
    color: theme.palette.customColors.accent.red10,
  },
}));

// --- COMPONENT ---
const TableArticlesContainer = ({
  articles,
  setArticles,
  dbCopy,
  setDbCopy,
  dbCopyUser,
  setDbCopyUser,
  currentSupplier,
  setDialogFeedback,
  downloadClickable,
  setDownloadClickable,
}) => {
  // VARIABLES
  const {t} = useTranslation();
  const theme = useTheme();
  const classes = useStyle();

  let idArtToChangeEkNeu,
    ekNeuNewValue,
    ekNeuOldValue,
    idArtToChangeAvail,
    artBeforeChangeAvail;

  // Request Mutations
  const [updateEkNeu] = useMutation(UPDATE_EKNEU, {
    onCompleted: () => {
      setDownloadClickable(true);
    },
    onError: (error) => {
      handleErrorEkNeu(error);
    },
  });

  const [updateAvail] = useMutation(UPDATE_AVAILABILITY, {
    onError: (error) => {
      handleErrorAvail(error);
    },
  });

  // FUNKTIONS
  const replaceBooleanWithCheckIcon = (action, value, id) => {
    return value
      ? (value = (
          <Icon
            onClick={(event) => changeAvail(action, id)}
            className="icon"
            style={{
              color: theme.palette.customColors.accent.green,
              cursor: 'pointer',
            }}>
            {'check_box'}
          </Icon>
        ))
      : (value = (
          <Icon
            onClick={(event) => changeAvail(action, id)}
            className="icon"
            style={{
              color: theme.palette.customColors.accent.red3,
              cursor: 'pointer',
            }}>
            {'check_box_outline_blank'}
          </Icon>
        ));
  };

  const changeEkNeu = (event, idToChange) => {
    // id des gewählten Artikels merken
    idArtToChangeEkNeu = idToChange;

    // store old value before typing into input
    const articleBeforeChange = dbCopy.find(
      (article) => article._id === idToChange
    );
    ekNeuOldValue = articleBeforeChange.ekneu;

    // neuen Input Wert als number holen
    const newValue = Number(utils.convertCommaToPoint(event.target.value));
    ekNeuNewValue = newValue;

    // Artikel, der in die DB geschriebn werden soll, zusammenstellen
    const articleToUpdate = articles.find(
      (article) => article._id === idToChange
    );
    const articleUpdated = {
      ...articleToUpdate,
      ekneu: newValue,
    };
    // Mutation aufrufen
    updateEkNeu({
      variables: {
        nummer: articleUpdated.artikelnr,
        liefNr: articleUpdated.lieferantnr,
        updset: {
          verfuegbar: articleUpdated.verfuegbar,
          nichtverfuegbar: articleUpdated.nichtverfuegbar,
          ekneu: articleUpdated.ekneu,
        },
      },
    });

    // optimistich denken, dass das Schreiben in DB gut gehen wird und ....
    updateEkNeuInArticles();
    updateEkNeuInDbCopy();
    updateEKNeuInDbUserCopy();
  };

  const updateEkNeuInArticles = () => {
    setArticles((prevState) => {
      let newArticles = prevState.map((article) => {
        if (article._id === idArtToChangeEkNeu) {
          let articleNewValue = {
            ...article,
            ekneu: ekNeuNewValue,
          };
          return articleNewValue;
        }
        return article;
      });
      return newArticles;
    });
  };

  const updateEkNeuInDbCopy = () => {
    setDbCopy((prevState) => {
      let newArticles = dbCopy.map((article) => {
        if (article._id === idArtToChangeEkNeu) {
          let aricleNewValue = {
            ...article,
            ekneu: ekNeuNewValue,
          };
          return aricleNewValue;
        }
        return article;
      });
      return newArticles;
    });
  };

  const updateEKNeuInDbUserCopy = () => {
    setDbCopyUser((prevState) => {
      let newArticles = dbCopyUser.map((article) => {
        if (article._id === idArtToChangeEkNeu) {
          let aricleNewValue = {
            ...article,
            ekneu: ekNeuNewValue,
          };
          return aricleNewValue;
        }
        return article;
      });
      return newArticles;
    });
  };

  const handleErrorEkNeu = (error) => {
    const errorMsgEkNeu = (
      <Typo subtitle1 className={classes.error}>
        {t('Änderung des EK Neu ist fehlgeschlagen.')}
        <br />
        {t('Bitte versuchen Sie es zu einem späteren Zeitpunkt.')}
      </Typo>
    );

    if (error.message === 'Error: 404: Not Found') {
      setDialogFeedback(
        <>
          <Typo subtitle1 className={classes.error}>
            {t('Datensatz nicht gefunden.')}
          </Typo>
          {errorMsgEkNeu}
        </>
      );
    } else {
      setDialogFeedback(errorMsgEkNeu);
    }

    resetEkNeuInDbCopy();
    resetEkNeuInDbCopyUser();
    resetEkNeuInArticles();

    setDownloadClickable(true);
  };
  const resetEkNeuInArticles = () => {
    setArticles((prevState) => {
      let resetedArticles = prevState.map((article) => {
        if (article._id === idArtToChangeEkNeu) {
          let aricleOldValue = {
            ...article,
            ekneu: ekNeuOldValue,
          };
          return aricleOldValue;
        }
        return article;
      });
      return resetedArticles;
    });
  };

  const resetEkNeuInDbCopy = () => {
    setDbCopy((prevState) => {
      let resetedArticles = prevState.map((article) => {
        if (article._id === idArtToChangeEkNeu) {
          let aricleOldValue = {
            ...article,
            ekneu: ekNeuOldValue,
          };
          return aricleOldValue;
        }
        return article;
      });
      return resetedArticles;
    });
  };

  const resetEkNeuInDbCopyUser = () => {
    setDbCopyUser((prevState) => {
      let resetedArticles = prevState.map((article) => {
        if (article._id === idArtToChangeEkNeu) {
          let aricleOldValue = {
            ...article,
            ekneu: ekNeuOldValue,
          };
          return aricleOldValue;
        }
        return article;
      });
      return resetedArticles;
    });
  };

  /// ------- handle Availavility --------
  const changeAvail = (action, idToChange) => {
    // id von gewählten Artikel merken
    idArtToChangeAvail = idToChange;

    // gewählten Artikel holen
    const articleClicked = articles.find(
      (article) => article._id === idToChange
    );
    const currentStatusVerf = articleClicked.verfuegbar;
    const currentStatusNichtverf = articleClicked.nichtverfuegbar;
    // artikel mit altem Stand merken
    artBeforeChangeAvail = articleClicked;

    if (!currentStatusVerf && !currentStatusNichtverf) {
      handleAvailNotSetYet(action, articleClicked);
    } else handleAvailIsSet(articleClicked);
  };

  const handleAvailNotSetYet = (action, artToUpdate) => {
    let articleUpdated;

    if (action === 'verfuegbarValue') {
      // geklickt auf "verfügbar"

      // Artikel, der in die DB geschriebn werden soll, zusammenstellen
      articleUpdated = {
        ...artToUpdate,
        verfuegbar: true,
        nichtverfuegbar: false,
      };
    } else {
      // geklickt auf "nicht verfügbar"

      // Artikel, der in die DB geschriebn werden soll, zusammenstellen
      articleUpdated = {
        ...artToUpdate,
        verfuegbar: false,
        nichtverfuegbar: true,
      };
    }

    // mutation aufrufen
    updateAvail({
      variables: {
        nummer: articleUpdated.artikelnr,
        liefNr: articleUpdated.lieferantnr,
        updset: {
          verfuegbar: articleUpdated.verfuegbar,
          nichtverfuegbar: articleUpdated.nichtverfuegbar,
        },
      },
    });

    // optimistich denken, dass das Schreiben in DB gut gehen wird
    // und articles und dbCopy mit neuen Werten überschreiben
    updateAvailInArticles(articleUpdated);
    updateAvailInDbCopy(articleUpdated);
    updateAvailInDbCopyUser(articleUpdated);
  };

  const handleAvailIsSet = (artToUpdate) => {
    // Artikel, der in die DB geschriebn werden soll, zusammenstellen
    const articleUpdated = {
      ...artToUpdate,
      verfuegbar: artToUpdate.verfuegbar ? false : true,
      nichtverfuegbar: artToUpdate.nichtverfuegbar ? false : true,
    };
    // mutation aufrufen
    updateAvail({
      variables: {
        nummer: articleUpdated.artikelnr,
        liefNr: articleUpdated.lieferantnr,
        updset: {
          verfuegbar: articleUpdated.verfuegbar,
          nichtverfuegbar: articleUpdated.nichtverfuegbar,
        },
      },
    });

    // optimistich denken, dass das Schreiben in DB gut gehen wird und ...
    updateAvailInArticles(articleUpdated);
    updateAvailInDbCopy(articleUpdated);
    updateAvailInDbCopyUser(articleUpdated);
  };

  const updateAvailInArticles = (articleUpdated) => {
    setArticles((prevState) => {
      let newArticles = prevState.map((article) => {
        if (article._id === idArtToChangeAvail) {
          let articleNewValue = {
            ...article,
            verfuegbar: articleUpdated.verfuegbar,
            nichtverfuegbar: articleUpdated.nichtverfuegbar,
          };
          return articleNewValue;
        }
        return article;
      });
      return newArticles;
    });
  };

  const updateAvailInDbCopy = (articleUpdated) => {
    setDbCopy((prevState) => {
      let newArticles = prevState.map((article) => {
        if (article._id === idArtToChangeAvail) {
          let articleNewValue = {
            ...article,
            verfuegbar: articleUpdated.verfuegbar,
            nichtverfuegbar: articleUpdated.nichtverfuegbar,
          };
          return articleNewValue;
        }
        return article;
      });
      return newArticles;
    });
  };

  const updateAvailInDbCopyUser = (articleUpdated) => {
    setDbCopyUser((prevState) => {
      let newArticles = prevState.map((article) => {
        if (article._id === idArtToChangeAvail) {
          let articleNewValue = {
            ...article,
            verfuegbar: articleUpdated.verfuegbar,
            nichtverfuegbar: articleUpdated.nichtverfuegbar,
          };
          return articleNewValue;
        }
        return article;
      });
      return newArticles;
    });
  };

  const handleErrorAvail = (error) => {
    const errorMsgAvail = (
      <Typo subtitle1 className={classes.error}>
        {t('Änderung der Verfügbarkeit ist fehlgeschlagen.')} <br />
        {t('Bitte versuchen Sie es zu einem späteren Zeitpunkt.')}
      </Typo>
    );

    if (error.message === 'Error: 404: Not Found') {
      setDialogFeedback(
        <>
          <Typo subtitle1 className={classes.error}>
            {t('Datensatz nicht gefunden.')}
          </Typo>
          {errorMsgAvail}
        </>
      );
    } else {
      setDialogFeedback(errorMsgAvail);
    }
    resetAvailInDbCopy();
    resetAvailInDbCopyUser();
    resetAvailInArticles();
  };

  const resetAvailInArticles = () => {
    setArticles((prevState) => {
      let newArticles = prevState.map((article) => {
        if (article._id === idArtToChangeAvail) {
          let articleNewValue = {
            ...article,
            verfuegbar: artBeforeChangeAvail.verfuegbar,
            nichtverfuegbar: artBeforeChangeAvail.nichtverfuegbar,
          };
          return articleNewValue;
        }
        return article;
      });
      return newArticles;
    });
  };

  const resetAvailInDbCopy = () => {
    setDbCopy((prevState) => {
      let newArticles = prevState.map((article) => {
        if (article._id === idArtToChangeAvail) {
          let articleNewValue = {
            ...article,
            verfuegbar: artBeforeChangeAvail.verfuegbar,
            nichtverfuegbar: artBeforeChangeAvail.nichtverfuegbar,
          };
          return articleNewValue;
        }
        return article;
      });
      return newArticles;
    });
  };

  const resetAvailInDbCopyUser = () => {
    setDbCopyUser((prevState) => {
      let newArticles = prevState.map((article) => {
        if (article._id === idArtToChangeAvail) {
          let articleNewValue = {
            ...article,
            verfuegbar: artBeforeChangeAvail.verfuegbar,
            nichtverfuegbar: artBeforeChangeAvail.nichtverfuegbar,
          };
          return articleNewValue;
        }
        return article;
      });
      return newArticles;
    });
  };

  // --- END FUNCTIONS

  // RENDER
  return (
    <>
      <TableArticles
        articles={articles}
        replaceBooleanWithCheckIcon={replaceBooleanWithCheckIcon}
        changeEkNeu={changeEkNeu}
        currentSupplier={currentSupplier}
        downloadClickable={downloadClickable}
        setDownloadClickable={setDownloadClickable}
      />
    </>
  );
};

TableArticlesContainer.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object),
  setArticles: PropTypes.func,
  dbCopy: PropTypes.arrayOf(PropTypes.object),
  setDbCopy: PropTypes.func,
  dbCopyUser: PropTypes.arrayOf(PropTypes.object),
  setDbCopyUser: PropTypes.func,
  currentSupplier: PropTypes.string,
  setDialogFeedback: PropTypes.func,
  downloadClickable: PropTypes.bool,
  setDownloadClickable: PropTypes.func,
};

export default TableArticlesContainer;
