import gql from 'graphql-tag';

const GET_ARTICLES_BY_USER = gql`
  query articlesAvailByUser($sort: String, $choice: String) {
    articlesAvailByUser(sort: $sort, choice: $choice) {
      _id
      verfuegbar
      nichtverfuegbar
      artikelnr
      bezeichnung
      ek
      ekneu
      lieferantnr
      lieferantname
    }
  }
`;

const GET_ARTICLES_BY_SUPPLIER = gql`
  query articlesAvailBySupplier($lieferantnr: String!) {
    articlesAvailBySupplier(liefNr: $lieferantnr) {
      _id
      verfuegbar
      nichtverfuegbar
      artikelnr
      bezeichnung
      ek
      ekneu
      lieferantnr
      lieferantname
    }
  }
`;

const AVAILABILITY_MAIL = gql`
  mutation availabilityMail($mailText: String) {
    availabilityMail(mailText: $mailText)
  }
`;

const UPDATE_EKNEU = gql`
  mutation updateEkNeu(
    $nummer: String!
    $liefNr: String!
    $updset: UpdateAvailabilityInput!
  ) {
    updateAvailability(artNr: $nummer, liefNr: $liefNr, set: $updset) {
      success
      message
      set {
        artikelnr
        lieferantnr
        ekneu
      }
    }
  }
`;

const UPDATE_AVAILABILITY = gql`
  mutation updateAvailability(
    $nummer: String!
    $liefNr: String!
    $updset: UpdateAvailabilityInput!
  ) {
    updateAvailability(artNr: $nummer, liefNr: $liefNr, set: $updset) {
      success
      message
      set {
        artikelnr
        lieferantnr
        verfuegbar
        nichtverfuegbar
      }
    }
  }
`;

const UPDATE_AVAILABILITY_BY_DISPLAY = gql`
  mutation updateAvailabilityByDisplay(
    $display: String!
    $liefNr: String!
    $updset: UpdateAvailabilityInput!
  ) {
    updateAvailabilityByDisplay(
      display: $display
      liefNr: $liefNr
      set: $updset
    ) {
      success
      message
      set {
        artikelnr
        lieferantnr
        verfuegbar
        nichtverfuegbar
      }
    }
  }
`;

const UPDATE_AVAILABILITY_BY_SEARCH = gql`
  mutation updateAvailabilityBySearch(
    $search: String!
    $liefNr: String!
    $updset: UpdateAvailabilityInput!
  ) {
    updateAvailabilityBySearch(search: $search, liefNr: $liefNr, set: $updset) {
      success
      message
      set {
        artikelnr
        lieferantnr
        verfuegbar
        nichtverfuegbar
      }
    }
  }
`;

export {
  GET_ARTICLES_BY_USER,
  GET_ARTICLES_BY_SUPPLIER,
  AVAILABILITY_MAIL,
  UPDATE_EKNEU,
  UPDATE_AVAILABILITY,
  UPDATE_AVAILABILITY_BY_DISPLAY,
  UPDATE_AVAILABILITY_BY_SEARCH,
};
