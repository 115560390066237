import light from './light';
/*
import dark from './dark';
import orange from './orange';
import cyan from './cyan';
import green from './green';

*/

const themes = {
  light,
  /*
  dark,
  orange,
  cyan,
  green,
  light
  */
};

export default themes;
