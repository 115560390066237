import React from 'react';

import {Layout, Theme} from '@tomfxs/pukeko';
import {GraphQLProvider} from './lib/graphql';
import {Router} from './Router';

import './Common/i18n';

import themes from './themes';

const theme = themes.light;

const App = () => {
  return (
    <GraphQLProvider>
      <Theme theme={theme}>
        <Layout>
          <Router />
        </Layout>
      </Theme>
    </GraphQLProvider>
  );
};

export default App;
