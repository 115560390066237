import React, {useEffect, useState} from 'react';
import {useQuery} from './lib/graphql';
import {GET_ARTICLES_BY_USER} from './queries/availability';
import auth from './lib/auth';

import Articles from './Articles';
import Loading from './components/Loading';

// --- COMPONENT ---
const ArticlesContainer = (props) => {
  // VARIABLES

  let sort = 'ART'; // by aricle number
  let choice = ''; // all articles
  const [articles, setArticles] = useState([]); // articles, currently shown in the articles table
  const [dbCopyUser, setDbCopyUser] = useState([]); // all aticles of user
  const [dbCopy, setDbCopy] = useState([]); // all aticles of supplier
  const [suppliers, setSuppliers] = useState([]); // array of supplier (user) and his subsupliers
  const [currentSupplier, setCurrentSupplier] = useState(''); // currently shosen supplier - supplierNumber as String

  const [dialogFeedback, setDialogFeedback] = useState(''); // if true, dialog window for Feedbacks is shown
  const [dialogReview, setDialogReview] = useState(false); // if true, dialog window for Review is shown
  const [reviewResult, setReviewResult] = useState([]);
  const [dialogSendMail, setDialogSendMail] = useState(false); // if true, dialog window for ExcelViaEMail is shown

  const [sorting, setSorting] = useState('byArticle'); // possible values: 'byArticle', 'byName'
  const [startSorting, setStartSorting] = useState(false);
  const [display, setDisplay] = useState('all'); // possible values: 'all', 'avail', 'notAvail', 'notEdited'
  const [search, setSearch] = useState('');

  const [isBtnTextAvail, setIsBtnTextAvail] = useState(true); // btn text "alle auf verfügbar / nicht verfügbar setzen". If true, shows "auf verfügbar"
  const [downloadClickable, setDownloadClickable] = useState(true); // flag for disabling btn "Als Excel downloaden"
  const [btnSetAllToIsClickable, setBtnSetAllToIsClickable] = useState(true); // flag for disabling btn "Alle auf verfügbar/nicht verfügab setzen"

  // load data
  const {
    loading: getArticlesLoading,
    error: getArticlesError,
    data: getArticlesData = {},
  } = useQuery(GET_ARTICLES_BY_USER, {
    variables: {sort, choice},
  });
  let {articlesAvailByUser = [{}]} = getArticlesData;

  // Life Cycle
  useEffect(() => {
    const suppliersOfUser = getSuppliers(articlesAvailByUser);
    setSuppliers(suppliersOfUser);
    setDbCopyUser(articlesAvailByUser);
    // wenn user keine sublieferanten hat
    if (suppliersOfUser.length === 1) {
      setDbCopy(articlesAvailByUser);
      setArticles(articlesAvailByUser);
      setCurrentSupplier(suppliersOfUser[0].supplierNumber);
    } else {
      setDbCopy([]);
      setArticles([]);
      setCurrentSupplier('');
    }
  }, [articlesAvailByUser]);

  // FUNKTIONS
  const logOut = () => {
    auth.removeToken();
    window.location = '/';
  };

  const getSuppliers = (articlesOfUser) => {
    let suppliers = [];

    articlesOfUser.forEach((article) => {
      const supplier = {
        supplierNumber: article.lieferantnr,
        supplierName: article.lieferantname,
      };

      const found = suppliers.find(
        (element) => element.supplierNumber === supplier.supplierNumber
      );

      if (!found) {
        suppliers.push(supplier);
      }
    });
    return suppliers;
  };

  // RENDER
  if (getArticlesLoading) return <Loading />;

  if (getArticlesError) {
    return <p>Error: {getArticlesError.message}</p>;
  }
  if (!getArticlesData) return <p>Es wurden keine Daten geladen</p>;

  if (getArticlesData) {
    return (
      <>
        <Articles
          articles={articles}
          setArticles={setArticles}
          dbCopy={dbCopy}
          setDbCopy={setDbCopy}
          suppliers={suppliers}
          currentSupplier={currentSupplier}
          setCurrentSupplier={setCurrentSupplier}
          dbCopyUser={dbCopyUser}
          setDbCopyUser={setDbCopyUser}
          search={search}
          setSearch={setSearch}
          sorting={sorting}
          setSorting={setSorting}
          startSorting={startSorting}
          setStartSorting={setStartSorting}
          reviewResult={reviewResult}
          setReviewResult={setReviewResult}
          display={display}
          setDisplay={setDisplay}
          dialogFeedback={dialogFeedback}
          setDialogFeedback={setDialogFeedback}
          dialogReview={dialogReview}
          setDialogReview={setDialogReview}
          dialogSendMail={dialogSendMail}
          setDialogSendMail={setDialogSendMail}
          logOut={logOut}
          downloadClickable={downloadClickable}
          setDownloadClickable={setDownloadClickable}
          isBtnTextAvail={isBtnTextAvail}
          setIsBtnTextAvail={setIsBtnTextAvail}
          btnSetAllToIsClickable={btnSetAllToIsClickable}
          setBtnSetAllToIsClickable={setBtnSetAllToIsClickable}
        />
      </>
    );
  }
};

export default ArticlesContainer;
