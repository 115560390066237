import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import {Table, Container, Group, Input, makeStyles} from '@tomfxs/pukeko';
import utils from '../utils/utils';

// CSS
const useStyle = makeStyles((theme) => ({
  wrapper: {
    overflowY: 'scroll',
    height: '380px',

    // jede Datenreihe bei Tabellendarstellung SMALL
    '& > div[data-testid*= "-small"]': {
      padding: 16,
    },
  },
  error: {
    color: theme.palette.customColors.accent.red10,
  },
  table: {
    padding: '5px 25px 20px 25px',

    // letzte Spalte (body und ausgeblendete head)
    '& > div > div > div:last-child': {
      paddingRight: 8,
    },
    '& > div:nth-child(odd) > div': {
      backgroundColor: theme.palette.customColors.themeGrey0,
    },
    '&> div > div:hover': {
      backgroundColor: theme?.palette?.primary?.dark,
    },
  },

  tableHeader: {
    backgroundColor: theme.palette.customColors.themeGrey1,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    padding: '0 25px',
    color: theme.palette.customColors.themeGrey10,
    fontSize: 18,
    wordWrap: 'break-word',
    '& > div': {
      paddingTop: '16px !important',
      paddingBottom: '8px !important',
    },
    '& > div[align= "right"]': {
      paddingRight: 8,
    },
    // display options
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  inputTableRow: {
    border: '1px solid #000',
    borderColor: theme?.palette?.primary?.light,
    paddingRight: '4px !important',
    paddingLeft: '4px !important',
    margin: '-4px 0 4px 0',
    borderRadius: '4px',
    backgroundColor: '#fff',
    '& input': {
      textAlign: 'right',
    },
  },
}));

// --- COMPONENT ---
const TableArticles = ({
  articles,
  replaceBooleanWithCheckIcon,
  changeEkNeu,
  setDownloadClickable,
}) => {
  // VARIABLES
  const {t} = useTranslation();
  /* Tabellenspalten
  jede Nummer im Array steht für die Spaltenbreite in einem Grid aus 12 möglichen Spalten.
  Muss als summe 12 ergeben */
  const columnsSize = [1, 2, 2, 3, 2, 2];
  const columnsLabels = [
    t('verfügbar'),
    t('nicht verfügbar'),
    t('ArtikelNr'),
    t('Bezeichnung'),
    t('EK'),
    t('EK Neu'),
  ];

  const columns = [
    {
      field: 'verfuegbar',
      label: columnsLabels[0],
      md: columnsSize[0],
      alignment: 'center',
    },
    {
      field: 'nichtverfuegbar',
      label: columnsLabels[1],
      md: columnsSize[1],
      alignment: 'center',
    },
    {
      field: 'artikelnr',
      label: columnsLabels[2],
      md: columnsSize[2],
    },
    {
      field: 'bezeichnung',
      label: columnsLabels[3],
      md: columnsSize[3],
    },
    {
      field: 'ek',
      label: columnsLabels[4],
      alignment: 'right',
      md: columnsSize[4],
    },
    {
      field: 'ekneu',
      label: columnsLabels[5],
      alignment: 'right',
      md: columnsSize[5],
    },
  ];

  const classes = useStyle();

  // FUNKTIONS
  const inputEkNeu = (ekneu, id) => {
    return (
      <>
        <Input
          data-testid="input-ekneu"
          value={utils.number(ekneu)}
          disableUnderline
          onFocus={() => setDownloadClickable(false)}
          onBlur={(event) => changeEkNeu(event, id)}
          className={classes.inputTableRow}
          style={{
            width: '50%',
            maxWidth: '70px',
          }}
        />
        <span>&nbsp;€</span>
      </>
    );
  };

  // RENDER
  return (
    <>
      {/* Header für die Tabelle  (NICHT Pukeko-Tabelle)*/}
      <Container
        className={classes.tableHeader}
        data-testid="tblArticlesHeader">
        <Group sm={columnsSize[0]} align="center">
          {columnsLabels[0]}
        </Group>
        <Group sm={columnsSize[1]} align="center">
          {columnsLabels[1]}
        </Group>
        <Group sm={columnsSize[2]} align="left">
          {columnsLabels[2]}
        </Group>
        <Group sm={columnsSize[3]} align="left">
          {columnsLabels[3]}
        </Group>
        <Group sm={columnsSize[4]} align="right">
          {columnsLabels[4]}
        </Group>
        <Group sm={columnsSize[5]} align="right">
          {columnsLabels[5]}
        </Group>
      </Container>
      {/* Standard-Tabelle von Pukeko */}
      <div className={classes.wrapper} data-testid="tblArticlesBody">
        <Table
          className={classes.table}
          data-testid="table"
          noHead
          columns={columns}
          labelWidth={3}
          small={['xs']}
          large={['sm', 'md', 'lg', 'xl']}
          data={articles.map((article) => {
            let myArticle = {...article};

            myArticle.verfuegbar = replaceBooleanWithCheckIcon(
              'verfuegbarValue',
              myArticle.verfuegbar,
              myArticle._id
            );
            myArticle.nichtverfuegbar = replaceBooleanWithCheckIcon(
              'nichtverfuegbarValue',
              myArticle.nichtverfuegbar,
              myArticle._id
            );
            myArticle.ek = utils.currency(myArticle.ek, false, 'EUR');
            myArticle.ekneu = inputEkNeu(myArticle.ekneu, myArticle._id);
            return myArticle;
          })}></Table>
      </div>
    </>
  );
};

TableArticles.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object),
  replaceBooleanWithCheckIcon: PropTypes.func,
  changeEkNeu: PropTypes.func,
  setDownloadClickable: PropTypes.func,
};

export default TableArticles;
