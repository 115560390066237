import React from 'react';
import PropTypes from 'prop-types';

import DisplayOptions from './DisplayOptions';

// --- COMPONENT ---
const DisplayOptionsContainer = ({
  dbCopy,
  display,
  setArticles,
  setDisplay,
  setStartSorting,
  setSearch,
  setIsBtnTextAvail,
  setBtnSetAllToIsClickable,
}) => {
  // VARIABLES

  // FUNCTIONS
  const showAll = () => {
    setSearch('');
    setDisplay('all');

    setArticles(dbCopy);
    setStartSorting(true);
    setIsBtnTextAvail(true);
    setBtnSetAllToIsClickable(true);
  };

  const showAvailableOnly = () => {
    setSearch('');
    setDisplay('avail');
    const availableArticles = dbCopy.filter((article) => {
      return article.verfuegbar === true;
    });
    setArticles(availableArticles);
    setStartSorting(true);
    setIsBtnTextAvail(false);
    setBtnSetAllToIsClickable(true);
  };

  const showNotAvailableOnly = () => {
    setSearch('');
    setDisplay('notAvail');
    const notAvailableArticles = dbCopy.filter((article) => {
      return article.nichtverfuegbar === true;
    });
    setArticles(notAvailableArticles);
    setStartSorting(true);
    setIsBtnTextAvail(true);
    setBtnSetAllToIsClickable(true);
  };

  const showNotDefinedOnly = () => {
    setSearch('');
    setDisplay('notEdited');
    let notDefinedArticles = dbCopy.filter((article) => {
      return article.verfuegbar === false && article.nichtverfuegbar === false;
    });
    setArticles(notDefinedArticles);
    setStartSorting(true);
    setIsBtnTextAvail(true);
    setBtnSetAllToIsClickable(true);
  };

  // RENDER
  return (
    <>
      <DisplayOptions
        display={display}
        showAll={showAll}
        showAvailableOnly={showAvailableOnly}
        showNotAvailableOnly={showNotAvailableOnly}
        showNotDefinedOnly={showNotDefinedOnly}
      />
    </>
  );
};

DisplayOptionsContainer.propTypes = {
  dbCopy: PropTypes.arrayOf(PropTypes.object),
  display: PropTypes.oneOf(['all', 'avail', 'notAvail', 'notEdited']),
  setArticles: PropTypes.func,
  setDisplay: PropTypes.func,
  setStartSorting: PropTypes.func,
  setSearch: PropTypes.func,
  setIsBtnTextAvail: PropTypes.func,
  setBtnSetAllToIsClickable: PropTypes.func,
};

export default DisplayOptionsContainer;
