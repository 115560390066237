import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  Button,
  Container,
  Group,
  Paper,
  Typo,
  makeStyles,
  Dialog,
  AppBar,
  useTheme,
} from '@tomfxs/pukeko';
import PropTypes from 'prop-types';

import AvailabilityChangeAllContainer from './components/AvailabilityChangeAllContainer';
import SortingContainer from './components/SortingContainer';
import DisplayOptionsContainer from './components/DisplayOptionsContainer';
import ExcelDownloadContainer from './components/ExcelDownloadContainer';
import SearchContainer from './components/SearchContainer';
import Review from './components/Review';
import DialogReview from './components/DialogReview';
import DialogExcelViaMailContainer from './components/DialogExcelViaMailContainer';
import TableArticlesContainer from './components/TableArticlesContainer';
import Supplier from './components/Supplier';

// CSS
const useStyle = makeStyles((theme) => ({
  wrapperTableArticles: {
    margin: '20px 0 24px 8px',
    borderRadius: '5px',
    backgroundColor: theme.palette.background.paper,
  },
  buttonWrapper: {
    margin: '24px 0 0 0',
    '& button': {
      margin: '0 6px 16px 0',
    },
    '& [role="button"]': {
      margin: '0 6px 16px 0',
    },
    '& button:last-child': {
      marginRight: 0,
    },
  },
  loadingWrapper: {
    marginTop: '40vh',
    width: '100%',
    textAlign: 'center',
  },
  logoutBtn: {
    position: 'fixed',
    zIndex: 1101,
    top: 16,
    right: 24,
    backgroundColor: `${theme.palette.button.background} !important`,
    color: `${theme.palette.button.text} !important`,
  },
  greenBtn: {
    backgroundColor: `${theme.palette.customColors.accent.greenDarker} !important`,
    color: `${theme.palette.button.text} !important`,
  },
  marginBtmXS: {
    marginBottom: 12,
  },
  marginBtmSM: {
    marginBottom: 24,
  },
  marginBtmMD: {
    marginBottom: 48,
  },
  marginBtmLG: {
    marginBottom: 64,
  },
}));

// --- COMPONENT ---
const Articles = ({
  articles,
  setArticles,
  dbCopy,
  setDbCopy,
  dbCopyUser,
  setDbCopyUser,
  currentSupplier,
  setCurrentSupplier,
  suppliers,
  search,
  setSearch,
  sorting,
  setSorting,
  startSorting,
  setStartSorting,
  reviewResult,
  setReviewResult,
  display,
  setDisplay,
  dialogFeedback,
  setDialogFeedback,
  dialogReview,
  setDialogReview,
  dialogSendMail,
  setDialogSendMail,
  logOut,
  downloadClickable,
  setDownloadClickable,
  isBtnTextAvail,
  setIsBtnTextAvail,
  btnSetAllToIsClickable,
  setBtnSetAllToIsClickable,
}) => {
  // VARIABLES
  const {t} = useTranslation();
  const theme = useTheme();
  const classes = useStyle();

  // RENDER
  return (
    <>
      <Dialog
        title="" //wird für verschiedene Feedbacks verwendet, daher keine Überschrift
        open={dialogFeedback ? true : false}
        onClose={() => setDialogFeedback('')}>
        {dialogFeedback}
      </Dialog>

      <DialogReview
        dialogReview={dialogReview}
        setDialogReview={setDialogReview}
        reviewResult={reviewResult}
      />
      <DialogExcelViaMailContainer
        dialogSendMail={dialogSendMail}
        setDialogSendMail={setDialogSendMail}
        setDialogFeedback={setDialogFeedback}
      />

      <Container>
        <AppBar
          backgroundColor={theme.palette.customColors.accent.red10}
          color="#fff"
          title={t('Verfügbarkeit')}
          data-testid="app-bar"></AppBar>

        <Group item xs={12}>
          <Button
            contained
            endIcon="logout"
            onClick={() => logOut()}
            className={classes.logoutBtn}
            small>
            {t('Abmelden')}
          </Button>
        </Group>
      </Container>

      <Container>
        <Group item xs={12} md={8} lg={6}>
          <Supplier
            suppliers={suppliers}
            currentSupplier={currentSupplier}
            setCurrentSupplier={setCurrentSupplier}
            setArticles={setArticles}
            setDbCopy={setDbCopy}
            dbCopyUser={dbCopyUser}
            setDbCopyUser={setDbCopyUser}
            setSearch={setSearch}
            setSorting={setSorting}
            setDisplay={setDisplay}
            setBtnSetAllToIsClickable={setBtnSetAllToIsClickable}></Supplier>
        </Group>
        <Group item xs={12} />
      </Container>

      <Container>
        <Group item xs={12} className={classes.wrapperTableArticles}>
          <TableArticlesContainer
            articles={articles}
            setDialogFeedback={setDialogFeedback}
            setArticles={setArticles}
            dbCopy={dbCopy}
            setDbCopy={setDbCopy}
            dbCopyUser={dbCopyUser}
            setDbCopyUser={setDbCopyUser}
            currentSupplier={currentSupplier}
            downloadClickable={downloadClickable}
            setDownloadClickable={setDownloadClickable}
          />
        </Group>
        <Group item xs={12}>
          <Typo subtitle1 gutterBottom>
            {t('Artikel insgesamt')}: {dbCopy.length}
          </Typo>
        </Group>
      </Container>

      <Container>
        <Group item xs={12} sm={6} md={4} lg={3} vertical="stretch">
          <Paper padded data-testid="search-wrapper">
            <SearchContainer
              search={search}
              setSearch={setSearch}
              setArticles={setArticles}
              dbCopy={dbCopy}
              setDisplay={setDisplay}
              setSorting={setSorting}
              setBtnSetAllToIsClickable={setBtnSetAllToIsClickable}
            />
          </Paper>
        </Group>
        <Group item xs={12} sm={6} md={3} lg={3} vertical="stretch">
          <Paper padded>
            <SortingContainer
              articles={articles}
              setArticles={setArticles}
              sorting={sorting}
              setSorting={setSorting}
              startSorting={startSorting}
              setStartSorting={setStartSorting}
            />
          </Paper>
        </Group>
        <Group item xs={12} sm={12} md={5} lg={6} vertical="stretch">
          <Paper padded>
            <DisplayOptionsContainer
              dbCopy={dbCopy}
              display={display}
              setArticles={setArticles}
              setDisplay={setDisplay}
              setStartSorting={setStartSorting}
              setSearch={setSearch}
              isBtnTextAvail={isBtnTextAvail}
              setIsBtnTextAvail={setIsBtnTextAvail}
              setBtnSetAllToIsClickable={setBtnSetAllToIsClickable}
            />
          </Paper>
        </Group>
      </Container>

      <Container>
        <Group item xs={12} className={classes.buttonWrapper}>
          <AvailabilityChangeAllContainer
            articles={articles}
            display={display}
            search={search}
            setArticles={setArticles}
            setDialogFeedback={setDialogFeedback}
            dbCopy={dbCopy}
            setDbCopy={setDbCopy}
            dbCopyUser={dbCopyUser}
            setDbCopyUser={setDbCopyUser}
            downloadClickable={downloadClickable}
            setDownloadClickable={setDownloadClickable}
            currentSupplier={currentSupplier}
            isBtnTextAvail={isBtnTextAvail}
            setIsBtnTextAvail={setIsBtnTextAvail}
            btnSetAllToIsClickable={btnSetAllToIsClickable}
            setBtnSetAllToIsClickable={setBtnSetAllToIsClickable}
          />
          <ExcelDownloadContainer
            dbCopyUser={dbCopyUser}
            downloadClickable={downloadClickable}
          />
          <Review
            dbCopyUser={dbCopyUser}
            setDialogReview={setDialogReview}
            setReviewResult={setReviewResult}></Review>
          <Button
            onClick={() => setDialogSendMail(true)}
            contained
            startIcon="email"
            className={classes.greenBtn}>
            {t('Fertig melden')}
          </Button>
        </Group>
      </Container>
    </>
  );
};

Articles.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object),
  setArticles: PropTypes.func,
  dbCopy: PropTypes.arrayOf(PropTypes.object),
  setDbCopy: PropTypes.func,
  dbCopyUser: PropTypes.arrayOf(PropTypes.object),
  setDbCopyUser: PropTypes.func,
  currentSupplier: PropTypes.string,
  setCurrentSupplier: PropTypes.func,
  suppliers: PropTypes.arrayOf(PropTypes.object),
  search: PropTypes.string,
  setSearch: PropTypes.func,
  sorting: PropTypes.oneOf(['byArticle', 'byName']),
  setSorting: PropTypes.func,
  startSorting: PropTypes.bool,
  setStartSorting: PropTypes.func,
  reviewResult: PropTypes.array,
  setReviewResult: PropTypes.func,
  display: PropTypes.oneOf(['all', 'avail', 'notAvail', 'notEdited']),
  setDisplay: PropTypes.func,
  dialogFeedback: PropTypes.string,
  setDialogFeedback: PropTypes.func,
  dialogReview: PropTypes.bool,
  setDialogReview: PropTypes.func,
  dialogSendMail: PropTypes.bool,
  setDialogSendMail: PropTypes.func,
  logOut: PropTypes.func,
  downloadClickable: PropTypes.bool,
  setDownloadClickable: PropTypes.func,
  isBtnTextAvail: PropTypes.bool,
  setIsBtnTextAvail: PropTypes.func,
  btnSetAllToIsClickable: PropTypes.bool,
  setBtnSetAllToIsClickable: PropTypes.func,
};

export default Articles;
