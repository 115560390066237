import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  useQuery,
  useMutation,
  useLazyQuery
} from '@apollo/client';

import auth from './auth';

const {
  config = {
    GRAPHQL: 'http://localhost/graphql',
  },
} = window;

console.log('Connect to', config.GRAPHQL);

const cache = new InMemoryCache();

const client = new ApolloClient({
  cache,
  link: new HttpLink({
    uri: config.GRAPHQL,
    headers: {
      authorization: auth.getToken(),
    },
  }),
});

const GraphQLProvider = ({children}) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);
export {GraphQLProvider, useQuery, useMutation, useLazyQuery};
