class Auth {
  isAuthenticated() {
    return this.getToken();
  }

  getToken() {
    return localStorage.getItem('marahMobile') /* ||
      'mpBsYTnxgRp99c4MuGtrvMUyAZAt79ffJC' */;
  }

  setToken(token) {
    return localStorage.setItem('marahMobile', token);
  }

  setUser(user) {
    localStorage.setItem('marahUser', user.user);
    return localStorage.setItem('marahFullname', user.fullname);
  }

  setCustomer(user) {
    return localStorage.setItem('marahCustomer', user.number);
  }

  getUser() {
    return {
      user: localStorage.getItem('marahUser'),
      fullname: localStorage.getItem('marahFullname'),
    };
  }

  getCustomer() {
    return localStorage.getItem('marahCustomer');
  }

  setPath(path) {
    return localStorage.setItem('marahPath', path);
  }

  getPath() {
    return localStorage.getItem('marahPath');
  }

  setTemp(path) {
    return localStorage.setItem('marahTemp', path);
  }

  getTemp() {
    return localStorage.getItem('marahTemp');
  }

  removeToken() {
    localStorage.removeItem('marahMobile');
    localStorage.removeItem('marahUser');
    localStorage.removeItem('marahFullname');
    localStorage.removeItem('marahCustomer');
    localStorage.removeItem('marahPath');
    localStorage.removeItem('marahTemp');
  }
}

export default new Auth();
