import React, {useState} from 'react';
import PropTypes from 'prop-types';
import utils from '../utils/utils';

import ExcelDownload from './ExcelDownload';

// --- COMPONENT ---
const ExcelDownloadContainer = ({dbCopyUser, downloadClickable}) => {
  // VARIABLES
  const [csvMap, setCsvMap] = useState([{}]);

  // FUNCTIONS
  const mapExcel = () => {
    let articlesToExcel = dbCopyUser.map((article) => {
      if (article.verfuegbar) {
        let updatedArticle = {
          ...article,
          bezeichnung: article.bezeichnung.replace('"', '""'),
          ek: utils.currency(article.ek, false, 'EUR'),
          ekneu: utils.currency(article.ekneu, false, 'EUR'),
          verfuegbar: '100',
        };
        return updatedArticle;
      } else {
        let updatedArticle = {
          ...article,
          bezeichnung: article.bezeichnung.replace('"', '""'),
          ek: utils.currency(article.ek, false, 'EUR'),
          ekneu: utils.currency(article.ekneu, false, 'EUR'),
          verfuegbar: '0',
        };
        return updatedArticle;
      }
    });
    setCsvMap(articlesToExcel);
  };

  // RENDER
  return (
    <>
      <ExcelDownload
        csvMap={csvMap}
        mapExcel={mapExcel}
        downloadClickable={downloadClickable}
      />
    </>
  );
};

ExcelDownloadContainer.propTypes = {
  dbCopyUser: PropTypes.arrayOf(PropTypes.object),
  downloadClickable: PropTypes.bool,
};

export default ExcelDownloadContainer;
