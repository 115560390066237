import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import {Button, makeStyles} from '@tomfxs/pukeko';
import {CSVLink} from 'react-csv';

// CSS
const useStyles = makeStyles((theme) => ({
  linkButton: {
    textDecoration: 'none ',
  },
  linkButtonDisabled: {
    textDecoration: 'none ',
    cursor: 'text',
  },
  button: {
    backgroundColor: `${theme.palette.button.background} !important`,
    color: `${theme.palette.button.text} !important`,
    textDecoration: 'none !important',
  },
  buttonDisabled: {
    backgroundColor: `${theme.palette.button.disabled.background} !important`,
    color: `${theme.palette.button.disabled.text} !important`,
    textDecoration: 'none !important',
    cursor: 'text',
  },
}));

// --- COMPONENT ---
const ExcelDownload = ({csvMap, mapExcel, downloadClickable}) => {
  // VARIABLES
  const {t} = useTranslation();
  const classes = useStyles();

  const headers = [
    {label: t('ArtikelNr'), key: 'artikelnr'},
    {label: t('Bezeichnung'), key: 'bezeichnung'},
    {label: t('EK'), key: 'ek'},
    {label: t('EK neu'), key: 'ekneu'},
    {label: t('verfügbar'), key: 'verfuegbar'},
  ];

  // RENDER
  return (
    <>
      {downloadClickable === false ? (
        <Button
          contained
          disabled={true}
          startIcon="file_download"
          className={classes.buttonDisabled}
          onClick={(event) => {
            event.preventDefault();
          }}>
          {t('Als Excel downloaden')}
        </Button>
      ) : (
        <CSVLink
          data={csvMap}
          headers={headers}
          separator={';'}
          enclosingCharacter={`"`}
          filename={t('verfuegbar') + '.csv'}
          role={'button'}
          className={classes.linkButton}
          data-testid="btn-excel-download">
          <Button
            contained
            startIcon="file_download"
            className={classes.button}
            onClick={() => {
              mapExcel();
            }}>
            {t('Als Excel downloaden')}
          </Button>
        </CSVLink>
      )}
    </>
  );
};

ExcelDownload.propTypes = {
  csvMap: PropTypes.arrayOf(PropTypes.object),
  mapExcel: PropTypes.func,
  downloadClickable: PropTypes.bool,
};

export default ExcelDownload;
