import React from 'react';
import {useMutation, /* useQuery, */ useLazyQuery} from '../lib/graphql';

import {
  UPDATE_AVAILABILITY_BY_DISPLAY,
  UPDATE_AVAILABILITY_BY_SEARCH,
  GET_ARTICLES_BY_SUPPLIER,
} from '../queries/availability';

import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {makeStyles, Typo} from '@tomfxs/pukeko';
import AvailabilityChangeAll from './AvailabilityChangeAll';

const useStyle = makeStyles((theme) => ({
  error: {
    color: theme.palette.customColors.accent.red10,
  },
}));

// --- COMPONENT ---
const AvailabilityChangeAllContainer = ({
  articles,
  setArticles,
  dbCopy,
  setDbCopy,
  dbCopyUser,
  setDbCopyUser,
  currentSupplier,
  display,
  search,
  setDialogFeedback,
  setDownloadClickable,
  isBtnTextAvail,
  setIsBtnTextAvail,
  btnSetAllToIsClickable,
  setBtnSetAllToIsClickable,
}) => {
  // VARIABLES
  const {t} = useTranslation();
  const classes = useStyle();

  // Mutations und Queries
  const [updateAvailByDisplayOption] = useMutation(
    UPDATE_AVAILABILITY_BY_DISPLAY,
    {
      onCompleted: () => {
        setDownloadClickable(true);
      },
      onError: (error) => {
        handleError(error);
      },
    }
  );

  const [updateAvailBySearch] = useMutation(UPDATE_AVAILABILITY_BY_SEARCH, {
    onCompleted: () => {
      setDownloadClickable(true);
    },
    onError: (error) => {
      handleError(error);
    },
  });

  const [getArticlesBySupplier] = useLazyQuery(GET_ARTICLES_BY_SUPPLIER, {
    variables: {lieferantnr: currentSupplier},
  });

  // FUNKTIONS
  const findNotAvailableOnly = (array) => {
    const availableArticles = articles.filter(
      (article) => article.verfuegbar === false
    );
    return availableArticles;
  };

  const findAvailableOnly = (array) => {
    const availableArticles = articles.filter(
      (article) => article.nichtverfuegbar === false
    );
    return availableArticles;
  };

  const changeArticle = (isBtnTextAvail, article) => {
    let updatedArticle;
    if (isBtnTextAvail) {
      updatedArticle = {
        ...article,
        verfuegbar: true,
        nichtverfuegbar: false,
      };
    } else {
      updatedArticle = {
        ...article,
        verfuegbar: false,
        nichtverfuegbar: true,
      };
    }
    return updatedArticle;
  };

  const handleAllAvailability = () => {
    setDownloadClickable(false);

    // Artikel finden, die geändert werden sollen und ArtikelNr merken
    let articlesToChange;
    if (isBtnTextAvail) {
      articlesToChange = findNotAvailableOnly(articles);
    } else {
      articlesToChange = findAvailableOnly(articles);
    }

    let artNrToChangeArray = [];
    articlesToChange.map((article) =>
      artNrToChangeArray.push(article.artikelnr)
    );

    // Mutation aufrufen je nach Fall
    if (search === '') {
      if (display === 'all' || display === 'notEdited') {
        if (isBtnTextAvail === true)
          updateAvailByDisplayOption({
            variables: {
              display: display,
              liefNr: currentSupplier,
              updset: {verfuegbar: true, nichtverfuegbar: false},
            },
          });

        if (isBtnTextAvail === false)
          updateAvailByDisplayOption({
            variables: {
              display: display,
              liefNr: currentSupplier,
              updset: {verfuegbar: false, nichtverfuegbar: true},
            },
          });
      }

      if (display === 'avail') {
        updateAvailByDisplayOption({
          variables: {
            display: display,
            liefNr: currentSupplier,
            updset: {verfuegbar: false, nichtverfuegbar: true},
          },
        });
      }

      if (display === 'notAvail') {
        updateAvailByDisplayOption({
          variables: {
            display: display,
            liefNr: currentSupplier,
            updset: {verfuegbar: true, nichtverfuegbar: false},
          },
        });
      }
    } else {
      if (isBtnTextAvail === true)
        updateAvailBySearch({
          variables: {
            search: search,
            liefNr: currentSupplier,
            updset: {verfuegbar: true, nichtverfuegbar: false},
          },
        });

      if (isBtnTextAvail === false)
        updateAvailBySearch({
          variables: {
            search: search,
            liefNr: currentSupplier,
            updset: {verfuegbar: false, nichtverfuegbar: true},
          },
        });
    }

    // optimistich denken, dass das Schreiben in DB gut gehen wird und ....
    updateAvailibilityInArticles();
    updateAvailibilityInDbCopy(isBtnTextAvail, artNrToChangeArray);
    updateAvailibilityInDbCopyUser(isBtnTextAvail, artNrToChangeArray);

    // Btn-Anzeige switchen
    setIsBtnTextAvail(!isBtnTextAvail);
  };

  const updateAvailibilityInArticles = () => {
    setArticles((prevState) => {
      let articlesChangedAllAvailability = articles.map((article) => {
        let updatedArticle;
        if (isBtnTextAvail) {
          updatedArticle = {
            ...article,
            verfuegbar: true,
            nichtverfuegbar: false,
          };
        } else {
          updatedArticle = {
            ...article,
            verfuegbar: false,
            nichtverfuegbar: true,
          };
        }
        return updatedArticle;
      });
      return articlesChangedAllAvailability;
    });
  };

  const updateAvailibilityInDbCopy = (isBtnTextAvail, artNrToChangeArray) => {
    setDbCopy((prevState) => {
      let articlesChangedAllAvailability = dbCopy.map((article) => {
        // wenn Artikel in artNrToChangeArray gemerkt wurde, dann ändern
        // sonst nicht
        const found = artNrToChangeArray.find(
          (artNr) => artNr === article.artikelnr
        );

        if (found) {
          const updatedArticle = changeArticle(isBtnTextAvail, article);
          return updatedArticle;
        } else {
          return article;
        }
      });
      return articlesChangedAllAvailability;
    });
  };

  const updateAvailibilityInDbCopyUser = (
    isBtnTextAvail,
    artNrToChangeArray
  ) => {
    setDbCopyUser((prevState) => {
      let articlesChangedAllAvailability = dbCopyUser.map((article) => {
        // wenn Artikel in artNrToChangeArray gemerkt wurde, dann ändern
        // sonst nicht
        const found = artNrToChangeArray.find(
          (artNr) => artNr === article.artikelnr
        );

        if (found) {
          const updatedArticle = changeArticle(isBtnTextAvail, article);
          return updatedArticle;
        } else {
          return article;
        }
      });
      return articlesChangedAllAvailability;
    });
  };

  const handleError = (error) => {
    if (error.message === 'Error: 404: Not Found') {
      setDialogFeedback(
        <>
          <Typo subtitle1 className={classes.error}>
            {t(
              'Änderung der Verfügbarkeit von ausgewählten Artikeln ist fehlgeschlagen.'
            )}
            <br />
            {t('Ein oder mehrere Datensätze nicht gefunden.')}
          </Typo>
        </>
      );
    } else {
      setDialogFeedback(
        <Typo subtitle1 className={classes.error}>
          {t(
            'Änderung der Verfügbarkeit von ausgewählten Artikeln ist fehlgeschlagen.'
          )}
          <br />
          {t('Bitte versuchen Sie es zu einem späteren Zeitpunkt.')}
        </Typo>
      );
    }

    // aktuelle Daten des Lieferanten aus der DB holen
    getArticlesBySupplier()
      .then((result) => {
        const {data} = result;
        const {articlesAvailBySupplier} = data;
        const articlesOfSupplierFromDB = articlesAvailBySupplier;
        return articlesOfSupplierFromDB;
      })
      .then((articlesOfSupplierFromDB) => {
        resetAvailabilityInArticles(articlesOfSupplierFromDB);
        resetAvailabilityInDbCopy(articlesOfSupplierFromDB);
        resetAvailabilityInDbCopyUser(articlesOfSupplierFromDB);
      });

    setDownloadClickable(true);
  };

  const resetAvailabilityInArticles = (articlesOfSupplierFromDB) => {
    setArticles((prevState) => {
      const newState = prevState.map((articlePrevState) => {
        // finde in neuen Daten diesen Artikel und gebe ihn zurück
        const newArticle = articlesOfSupplierFromDB.find(
          (articleFromDB) =>
            articleFromDB.artikelnr === articlePrevState.artikelnr
        );
        return newArticle;
      });
      return newState;
    });
  };

  const resetAvailabilityInDbCopy = (articlesOfSupplierFromDB) => {
    setDbCopy(articlesOfSupplierFromDB);
  };

  const resetAvailabilityInDbCopyUser = (articlesOfSupplierFromDB) => {
    setDbCopyUser((prevState) => {
      const newState = prevState.map((articlePrevStaate) => {
        if (articlePrevStaate.lieferantnr === currentSupplier) {
          // finde in neuen Daten diesen Artikel und gebe ihn zurück
          const newArticle = articlesOfSupplierFromDB.find(
            (articleFromDB) =>
              articleFromDB.artikelnr === articlePrevStaate.artikelnr
          );
          return newArticle;
        } else return articlePrevStaate;
      });
      return newState;
    });
  };

  // Button "alle auf verfügbar / nicht verfügbar" deaktivieren
  if (
    (display === 'avail') & (isBtnTextAvail === true) ||
    (display === 'notAvail') & (isBtnTextAvail === false) ||
    (display === 'notEdited') & (isBtnTextAvail === false)
  ) {
    setBtnSetAllToIsClickable(false);
  }

  // RENDER
  return (
    <>
      <AvailabilityChangeAll
        handleAllAvailability={handleAllAvailability}
        isBtnTextAvail={isBtnTextAvail}
        display={display}
        btnSetAllToIsClickable={btnSetAllToIsClickable}
        setBtnSetAllToIsClickable={setBtnSetAllToIsClickable}
      />
    </>
  );
};

AvailabilityChangeAllContainer.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object),
  setArticles: PropTypes.func,
  dbCopy: PropTypes.arrayOf(PropTypes.object),
  setDbCopy: PropTypes.func,
  dbCopyUser: PropTypes.arrayOf(PropTypes.object),
  setDbCopyUser: PropTypes.func,
  currentSupplier: PropTypes.string,
  display: PropTypes.oneOf(['all', 'avail', 'notAvail', 'notEdited']),
  search: PropTypes.string,
  setDialogFeedback: PropTypes.func,
  setDownloadClickable: PropTypes.func,
  isBtnTextAvail: PropTypes.bool,
  setIsBtnTextAvail: PropTypes.func,
  btnSetAllToIsClickable: PropTypes.bool,
  setBtnSetAllToIsClickable: PropTypes.func,
};

export default AvailabilityChangeAllContainer;
