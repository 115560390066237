const utils = {
  currency(value, notEmpty, currency = 'EUR') {
    if ((!value && notEmpty) || typeof value !== 'number') {
      return '';
    }

    const language =
      navigator.language ||
      navigator.browserLanguage ||
      (navigator.languages || ['de-DE'])[0];
    const formatter = new Intl.NumberFormat(language, {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  },

  number(value, notEmpty) {
    if (!value && notEmpty) {
      return '';
    }

    const formatter = new Intl.NumberFormat('de-DE', {
      style: 'decimal',
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  },

  convertCommaToPoint(string) {
    return string.replace(',', '.');
  },
};

export default utils;
