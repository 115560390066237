import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useMutation} from '../lib/graphql';
import {AVAILABILITY_MAIL} from '../queries/availability';

import DialogExcelViaMail from './DialogExcelViaMail';

import {Typo, makeStyles} from '@tomfxs/pukeko';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.customColors.accent.red10,
  },
}));

// --- COMPONENT ---
const DialogExcelViaMailContainer = ({
  dialogSendMail,
  setDialogSendMail,
  setDialogFeedback,
}) => {
  // VARIABLES
  const {t} = useTranslation();
  const classes = useStyles();

  const [messageText, setMessageText] = useState(''); // current value of input field "Ihre Nachricht"

  // Mail-Versand
  const [handleMail, {loading, error, data}] = useMutation(AVAILABILITY_MAIL, {
    variables: {mailText: messageText},
    onCompleted: () => {
      handleFeedbackSuccess();
    },
    onError: () => {
      handleFeedbackError();
    },
  });

  const {graphQLErrors = []} = error || {};
  const message =
    graphQLErrors.length > 0
      ? graphQLErrors[0].message
      : error && error.message;

  // FUNCTIONS
  const cancelDialogSendMail = () => {
    setDialogSendMail(false);
    setMessageText('');
  };

  const sendMail = () => {
    handleMail();
    setMessageText('');
  };

  const handleFeedbackSuccess = () => {
    setDialogSendMail(false);
    setDialogFeedback(
      <Typo subtitle1>{t('E-Mail wurde erfolgreich versendet.')}</Typo>
    );
  };

  const handleFeedbackError = () => {
    setDialogSendMail(false);
    if (message === 'No recipient email address') {
      setDialogFeedback(
        <Typo variant="body1" className={classes.error}>
          {t('Es wurde keine E-Mail versendet.')}
          <br />
          {t('Sie haben bei uns keine E-Mail-Adresse hinterlegt.')}
        </Typo>
      );
    }
    if (message === 'No supplier') {
      setDialogFeedback(
        <Typo variant="body1" className={classes.error}>
          {t('Es wurde keine E-Mail versendet.')}
          <br />
          {t('Lieferant nicht gefunden.')}
        </Typo>
      );
    }
    if (message === 'No selecton') {
      setDialogFeedback(
        <Typo variant="body1" className={classes.error}>
          {t('Es wurde keine E-Mail versendet.')}
          <br />
          {t('Selektion kann nicht erstellt werden.')}
        </Typo>
      );
    }
    if (message === 'No File') {
      setDialogFeedback(
        <Typo variant="body1" className={classes.error}>
          {t('Es wurde keine E-Mail versendet.')}
          <br />
          {t('Exceldatei kann nicht erstellt werden.')}
        </Typo>
      );
    }
    if (message === 'No email client') {
      setDialogFeedback(
        <Typo variant="body1" className={classes.error}>
          {t('Es wurde keine E-Mail versendet.')}
          <br />
          {t('E-Mail Client kann nicht geöffnet werden!')}
        </Typo>
      );
    } else {
      setDialogFeedback(
        <Typo variant="body1" className={classes.error}>
          {t('Es wurde keine E-Mail versendet.')}
        </Typo>
      );
    }
  };

  // RENDER
  return (
    <>
      <DialogExcelViaMail
        dialogSendMail={dialogSendMail}
        setDialogSendMail={setDialogSendMail}
        cancelDialogSendMail={cancelDialogSendMail}
        messageText={messageText}
        setMessageText={setMessageText}
        sendMail={sendMail}
        loading={loading}
        error={error}
        data={data}
      />
    </>
  );
};

DialogExcelViaMailContainer.propTypes = {
  dialogSendMail: PropTypes.bool,
  setDialogSendMail: PropTypes.func,
  setDialogFeedback: PropTypes.func,
};

export default DialogExcelViaMailContainer;
