import React from 'react';
import PropTypes from 'prop-types';
import Search from './Search';

// --- COMPONENT ---
const SearchContainer = ({
  dbCopy,
  search,
  setSearch,
  setArticles,
  setDisplay,
  setSorting,
  setBtnSetAllToIsClickable,
}) => {
  // FUNCTIONS
  const handleSearch = () => {
    const searchResult = dbCopy.filter((article) => {
      let searchAsRegEx = new RegExp(search, 'i');
      // article.artikelnr und article.bezeichnung nach RegEx durchsuchen
      const resultSearchArtNr = searchAsRegEx.exec(article.artikelnr);
      const resultSearchBezeichnung = searchAsRegEx.exec(article.bezeichnung);

      if (resultSearchArtNr || resultSearchBezeichnung) {
        return article;
      } else {
        return null;
      }
    });
    setArticles(searchResult);

    setDisplay('all');
    setSorting('byArticle');
    setBtnSetAllToIsClickable(true);
  };

  const searchOnKeyReturn = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  // RENDER
  return (
    <>
      <Search
        search={search}
        setSearch={setSearch}
        handleSearch={handleSearch}
        searchOnKeyReturn={searchOnKeyReturn}
      />
    </>
  );
};

SearchContainer.propTypes = {
  dbCopy: PropTypes.arrayOf(PropTypes.object),
  search: PropTypes.string,
  setSearch: PropTypes.func,
  setArticles: PropTypes.func,
  setDisplay: PropTypes.func,
  setSorting: PropTypes.func,
  setBtnSetAllToIsClickable: PropTypes.func,
};

export default SearchContainer;
