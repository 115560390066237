import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Typo, makeStyles, Icon} from '@tomfxs/pukeko';

// CSS
const useStyles = makeStyles((theme) => ({
  headline: {
    marginBottom: 7,
    fontSize: '1.125rem',
  },
  marginTop: {
    marginTop: '16px',
  },
  formControl: {
    display: 'flex',
    '& .icon': {
      margin: '1px 8px 0 0',
      alignSelf: 'flex-start',
      color: theme.palette.customColors.accent.red3,
    },
    '& .label': {
      alignSelf: 'flex-start',
    },
  },
}));

// --- COMPONENT ---
const Sorting = (props) => {
  const {sorting, handleSort} = props;
  // VARIABLES
  const {t} = useTranslation();
  const classes = useStyles();

  // RENDER
  return (
    <>
      <Typo title6 className={classes.headline}>
        {t('Sortieren')}:
      </Typo>
      <div
        data-testid="sortByArticle"
        onClick={() => {
          handleSort('sortByArticle');
        }}
        className={classes.formControl}>
        <Icon className="icon">
          {sorting === 'byArticle'
            ? 'radio_button_checked'
            : 'radio_button_unchecked'}
        </Icon>
        <Typo subtitle1 className="label">
          {t('nach ArtikelNr')}
        </Typo>
      </div>
      <div
        data-testid="sortByName"
        onClick={() => {
          handleSort('sortByName');
        }}
        className={classes.formControl}>
        <Icon className="icon">
          {sorting === 'byName'
            ? 'radio_button_checked'
            : 'radio_button_unchecked'}
        </Icon>
        <Typo subtitle1 className="label">
          {t('nach Bezeichnung')}
        </Typo>
      </div>
    </>
  );
};

Sorting.propTypes = {
  sorting: PropTypes.oneOf(['byArticle', 'byName']),
  handleSort: PropTypes.func,
};

export default Sorting;
