import React from 'react';
import {Spinner, makeStyles} from '@tomfxs/pukeko';

// CSS
const useStyle = makeStyles(() => ({
  loadingWrapper: {
    marginTop: '40vh',
    width: '100%',
    textAlign: 'center',
  },
}));

const Loading = () => {
  // VARIABLES
  const classes = useStyle();
  return (
    <div className={classes.loadingWrapper}>
      <Spinner color="inherit" />
    </div>
  );
};

export default Loading;
