import React from 'react';

import {Redirect, Route} from 'react-router-dom';

import auth from './auth';

const PrivateRoute = ({component: Component, location = {}, ...rest}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const {match = {}} = props;
        const {params = {}} = match;
        const join = {...props, ...params};

        return auth.isAuthenticated() ? (
          <Component {...join} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {from: props.location},
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
