import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Button, makeStyles} from '@tomfxs/pukeko';

const useStyle = makeStyles((theme) => ({
  error: {
    color: theme.palette.customColors.accent.red10,
  },
  button: {
    backgroundColor: `${theme.palette.button.background} !important`,
    color: `${theme.palette.button.text} !important`,
  },
  buttonDisabled: {
    backgroundColor: `${theme.palette.button.disabled.background} !important`,
    color: `${theme.palette.button.disabled.text} !important`,
    cursor: 'text',
  },
}));

// --- COMPONENT ---
const AvailabilityChangeAll = ({
  handleAllAvailability,
  isBtnTextAvail,
  btnSetAllToIsClickable,
}) => {
  // VARIABLES
  const {t} = useTranslation();
  const classes = useStyle();

  return (
    <>
      {btnSetAllToIsClickable === true ? (
        <Button
          contained
          className={classes.button}
          onClick={handleAllAvailability}
          startIcon="check_box'"
          data-testid="availChangeAll">
          {t('Alle auf')}
          {
            <span
              data-testid="availChangeAll-btnText"
              style={{margin: '0 10px', color: 'black'}}>
              {isBtnTextAvail ? t('verfügbar') : t('nicht verfügbar')}
            </span>
          }
          {t('setzen')}
        </Button>
      ) : (
        <Button
          contained
          className={classes.buttonDisabled}
          onClick={(event) => {
            event.preventDefault();
          }}
          startIcon="check_box'"
          data-testid="availChangeAll">
          {t('Alle auf')}
          {
            <span
              data-testid="availChangeAll-btnText"
              style={{margin: '0 10px'}}>
              {isBtnTextAvail ? t('verfügbar') : t('nicht verfügbar')}
            </span>
          }
          {t('setzen')}
        </Button>
      )}
    </>
  );
};

AvailabilityChangeAll.propTypes = {
  handleAllAvailability: PropTypes.func,
  isBtnTextAvail: PropTypes.bool,
  btnSetAllToIsClickable: PropTypes.bool,
};

export default AvailabilityChangeAll;
