import React from 'react';
import PropTypes from 'prop-types';
import Sorting from './Sorting';

// --- COMPONENT ---
const SortingContainer = (props) => {
  const {
    articles,
    setArticles,
    sorting,
    setSorting,
    startSorting,
    setStartSorting,
  } = props;

  // FUNCTIONS
  const compareNr = (a, b) => {
    if (a.artikelnr < b.artikelnr) return -1;
    if (a.artikelnr > b.artikelnr) return 1;
    return 0;
  };

  const compareBez = (a, b) => {
    if (a.bezeichnung.toUpperCase() < b.bezeichnung.toUpperCase()) return -1;
    if (a.bezeichnung.toUpperCase() > b.bezeichnung.toUpperCase()) return 1;
  };

  const handleSort = (action) => {
    let articlesToSort = articles.map((article) => {
      let updatedArticle = {
        ...article,
      };
      return updatedArticle;
    });

    switch (action) {
      case 'sortByArticle':
        setSorting('byArticle');
        articlesToSort.sort(compareNr);
        setArticles(articlesToSort);
        break;
      case 'sortByName':
        setSorting('byName');
        articlesToSort.sort(compareBez);
        setArticles(articlesToSort);
        break;
      default:
        setSorting('byArticle');
    }
  };

  if (startSorting === true) {
    const action = sorting === 'byArticle' ? 'sortByArticle' : 'sortByName';
    handleSort(action);
    setStartSorting(false);
  }

  // RENDER
  return (
    <>
      <Sorting sorting={sorting} handleSort={handleSort} />
    </>
  );
};

SortingContainer.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object),
  setArticles: PropTypes.func,
  sorting: PropTypes.oneOf(['byArticle', 'byName']),
  setSorting: PropTypes.func,
  startSorting: PropTypes.bool,
  setStartSorting: PropTypes.func,
};

export default SortingContainer;
