import {colors} from '@tomfxs/pukeko';

const blue = colors.blue;
const red = colors.red;

const palette = [
  '#1F5AA6',
  '#3981BF',
  '#5E9ABF',
  '#9CC1D9',
  '#E2EAF2',
  '#3a81c0',
  '#263238',
];

/* additional colors VerfügbarkeitsApp */
const customPalette = ['#edf2f7', '#a7bfd7'];

const colorsPalette = [];
for (let i = 900; i >= 100; i -= 100) {
  colorsPalette.push(blue[i]);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  palette: {
    mode: 'light',
    background: {
      default: '#D9E2EC',
      paper: '#FCFFFF',
    },
    primary: {
      main: palette[4],
      light: palette[3],
      dark: '#BCCCDC',
      contrastText: '#334E68',
    },

    border: '#647ACB',
    contrast: '#829AB1',
    icon: '#647ACB',
    subtitle: '#627D98',
    customColors: {
      themeGrey0: customPalette[0], // '#edf2f7'
      themeGrey1: customPalette[1], // '#a7bfd7'
      themeGrey10: palette[6], // '#263238'

      accent: {
        red0: red[50],
        red1: red[100],
        red2: red[200],
        red3: red[300],
        red9: red[800],
        red10: red[900],
        green: '#8ab92d', // shade 45% of yellowgreen
        greenDarker: '#7ba428', // shade 40%  of yellowgreen
      },
    },
    button: {
      background: red[300],
      text: '#fff',
      disabled: {
        background: customPalette[1],
        text: '#fff',
      },
    },
  },
  props: {
    MuiPaper: {
      elevation: 0,
    },
  },
  typography: {
    useNextVariants: true,
  },
  logo: '/img/sauterundheld_logo_web_RGB.svg',
};
